import Paginado from "../Paginado/Paginado";
import c from "./ourworks.module.css";


export default function OurWorks() {

    const images = [
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1729847549/Climatizaciones/o6qw04kagptsvt4iptir.png",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1729847555/Climatizaciones/xywfcfs41c87bksv323g.png",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1729847558/Climatizaciones/rwlf4izwm6krenzcmm9d.png",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1729847563/Climatizaciones/rsstbrz4yeiiqprhlzpp.jpg",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1729847569/Climatizaciones/uox2ejqhnedrtqsavq5c.jpg",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1729847570/Climatizaciones/fx0byaez2hifywlrnwpj.jpg",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1730304481/Climatizaciones/ps98htekd1eeacbghjmt.jpg",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1730268844/Climatizaciones/purbwycmdovc7w1tf5ci.jpg",
        "https://res.cloudinary.com/dapq4icmj/image/upload/v1731417460/Climatizaciones/gxsofnxcpl5xfbh2fowd.jpg"
    ];

    /* const images = [
        {id: 1, imgUrl:"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847549/Climatizaciones/o6qw04kagptsvt4iptir.png"},
        {id: 2, imgUrl:"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847555/Climatizaciones/xywfcfs41c87bksv323g.png"},
        {id: 3, imgUrl:"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847558/Climatizaciones/rwlf4izwm6krenzcmm9d.png"},
        {id: 4, imgUrl:"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847563/Climatizaciones/rsstbrz4yeiiqprhlzpp.jpg"},
        {id: 5, imgUrl:"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847569/Climatizaciones/uox2ejqhnedrtqsavq5c.jpg"},
        {id: 6, imgUrl:"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847570/Climatizaciones/fx0byaez2hifywlrnwpj.jpg"}
    ]; */


    return(<div className={c.ourWorks}>
    
    <div className={c.container}>

       <div className={c.titulo}><p>NUESTROS TRABAJOS</p></div>  

       <Paginado images={images}/>

       <div className={c.descripcion}>
        <p>Realizado por <span className={c.semiBold}>PROFESIONALES</span> en el sector<br/>
Con más de <span className={c.bold}>15 años</span> de trayectoria en el rubro <span className={`${c.italic} ${c.semiBold}`}>MTClimatización©</span> <br/>continúa marcando la pauta en soluciones de <span className={c.semiBold}>confort y eficiencia.</span></p>
       </div>
    </div>

     <div  id="why" className={c.ondaBackground}>
       <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848954/Climatizaciones/cvstndqfu82moahwu36t.png" className={c.onda} alt="" />
    </div> 
     
    </div>)
}