import c from "./services.module.css";

export default function Services() {

    return(<div id="servicios" className={c.services}>
    
    <div className={c.container}>
     
     <div className={c.divContainer}><h2>SERVICIOS QUE PRESTAMOS</h2></div>
 
     <div className={c.imagesCont}>

      <div className={c.imgCont}>

         <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848822/Climatizaciones/c64zhjhru9qdkasn4kle.jpg" className={c.airePng} alt="" />

         <div className={c.itemHolder}>
            <h5>Servicio Técnico de Calderas</h5>

            <div className={c.itemHolderInfo} >
               <div className={c.none}>
               <ul className={c.ulCaldera}>
                  <li>Instalación de Calderas</li>
                  <li>Mantenimiento de Calderas.</li>
                  <li>Reparación de Calderas.</li>
               </ul>

               <p>Pedí tu Presupuesto!</p>
               </div>
               <a href="https://wa.link/e023hh" target="_blank" rel="noreferrer"><div className={`${c.itemHolderInfo} ${c.button} ${c.itemHolderInfo2}`}>
                  <div className={c.arrow}></div>
               </div></a>
            </div>
         </div>

         <div className={c.orange}></div>
      </div>

      <div className={`${c.imgCont}`}>
         

         <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848829/Climatizaciones/uchwrrufyoyrdcnl197v.png" className={c.calderaJpg} alt="" />

         <div className={c.itemHolder}>
            <h5 className={c.h5Aire}>Servicio Técnico de Aire Acondicionado</h5>
            
            <div className={c.itemHolderInfo} >
               <div className={c.none}>
               <ul className={c.ulAire}>
                  <li>Instalación de Aire Acondicionado</li>
                  <li>Mantenimiento de Aire Acondicionado.</li>
                  <li>Reparación de Aire Acondicionado.</li>
               </ul>

               <p>Pedí tu Presupuesto!</p>
               </div>
               <a href="https://wa.link/e023hh" target="_blank" rel="noreferrer"><div className={`${c.itemHolderInfo} ${c.button} ${c.itemHolderInfo2}`}>
                  <div className={c.arrow}></div>
               </div></a>
            </div>
         </div>
         <div className={c.orange}></div>

      </div>   

     </div>

    </div>
    
    <div className={c.ondaBackground}>
       <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848954/Climatizaciones/cvstndqfu82moahwu36t.png" className={c.onda} alt="" />
    </div>

    </div>)
}