// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brands_brands__ntOvX{
    /* background-image: url("https://res.cloudinary.com/dapq4icmj/image/upload/v1729848383/Climatizaciones/ara2zkwsfadt5bb9uhay.jpg"), linear-gradient(rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58));
    background-size: cover;
    background-position: center;
    width: 100%;
    background-blend-mode: overlay; */
    position: relative; z-index: 1;
}

.brands_band__iqFSC{
    padding-top: 4rem;
    padding-bottom: 2rem;
}
.brands_bandContainer__cVAjW{
    padding: 1rem 0rem 1rem 0rem;
    font-size: 2rem;
    font-weight: 300;
   /*  background: black; */
}

.brands_container__kDPj6{
    max-width: 1252px;
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Brands/brands.module.css"],"names":[],"mappings":"AAAA;IACI;;;;qCAIiC;IACjC,kBAAkB,EAAE,UAAU;AAClC;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;GACjB,wBAAwB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".brands{\n    /* background-image: url(\"https://res.cloudinary.com/dapq4icmj/image/upload/v1729848383/Climatizaciones/ara2zkwsfadt5bb9uhay.jpg\"), linear-gradient(rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58));\n    background-size: cover;\n    background-position: center;\n    width: 100%;\n    background-blend-mode: overlay; */\n    position: relative; z-index: 1;\n}\n\n.band{\n    padding-top: 4rem;\n    padding-bottom: 2rem;\n}\n.bandContainer{\n    padding: 1rem 0rem 1rem 0rem;\n    font-size: 2rem;\n    font-weight: 300;\n   /*  background: black; */\n}\n\n.container{\n    max-width: 1252px;\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brands": `brands_brands__ntOvX`,
	"band": `brands_band__iqFSC`,
	"bandContainer": `brands_bandContainer__cVAjW`,
	"container": `brands_container__kDPj6`
};
export default ___CSS_LOADER_EXPORT___;
