import c from "./form.module.css"
import { useState } from "react";
import axios from "axios"
import validations from "./validations";
import ReCAPTCHA from "react-google-recaptcha";

export default function Form() {

   const [captchaValido, setCaptchaValido] = useState(false)
   const [touched, setTouched] = useState({});
   const [errors,setErrors] = useState({})

   const [form, setForm] = useState({
   name: "",
   email: "",
   number: "",
   marca: "Peisa",
   problema: "No enciende",
   message: ""
   });

   
   function onChange(value) {
      setCaptchaValido(true)
    }

   function onClick(e){
         setTouched({...touched, [e.target.name]: true})
   }

   function handleInputChange(e) {
      e.preventDefault();
      setForm({...form, [e.target.name]: e.target.value});
      
      setErrors(validations(
         {...form, [e.target.name]: e.target.value}, touched
         ))
   }

   async function handleSubmit() {
      try{
         
          const response = await axios.post("https://mtclimatizacionservidores-production.up.railway.app/sendForm", form);
          console.log(response)
          if (response.status === 200) {
              alert("Formulario enviado con éxito.");
              setForm({
               name: "",
               email: "",
               number: "",
               marca: "Peisa",
               problema: "No enciende",
               message: ""
           });
          }
      } catch (error) {
          console.error("Error al enviar el formulario:", error);
          alert("Hubo un error al enviar el formulario.");
      }
  }

    return(<div className={c.form}>
    
    <div className={c.container}>
     
     <h2>DEJANOS TU CONSULTA</h2>
     
     <input type="text" name="name" id="name" value={form.name} placeholder="Nombre" onClick={onClick} onChange={(e) => handleInputChange(e)}/>
     {errors.name && <p className={c.warning}>{errors.name}</p>}

     <input type="email" name="email" id="email" placeholder="Email" value={form.email} onClick={onClick} onChange={(e) => handleInputChange(e)}/>
     {errors.email && <p className={c.warning}>{errors.email}</p>}

     <input type="text" name="number" id="number" placeholder="Teléfono (Recomendado)" value={form.number} onChange={(e) => handleInputChange(e)}/>

     <div className={c.select}>
     <label htmlFor=""> Seleccione la Marca:</label>
     <select name="marca" id="marca" value={form.marca} onChange={(e) => handleInputChange(e)}>
        <option value="Peisa">Peisa</option>
        <option value="Baxi">Baxi</option>
        <option value="Ariston">Ariston</option>
        <option value="Caldaia">Caldaia</option>
        <option value="Ferroli">Ferroli</option>
        <option value="Orbis">Orbis</option>
        <option value="Otro">Otro</option>
     </select>
     </div>  

     <div className={c.select}>
     <label htmlFor=""> Seleccione el problema:</label>
     <select name="problema" value={form.problema} id="" onChange={(e) => handleInputChange(e)}>
        <option value="No enciende">No enciende</option>
        <option value="Rotura">Rotura</option>
        <option value="Pérdida">Pérdida</option>
        <option value="Filtración">Filtración</option>
        <option value="Otro">Otro</option>
     </select>
     </div>  

     <textarea name="message" value={form.message} id="" cols="30" rows="5" placeholder="Mensaje..." onChange={(e) => handleInputChange(e)}></textarea>



     <div className={c.cache}>
       <ReCAPTCHA
       sitekey="6LcKn28qAAAAACqG0b8uJrms1Me7_2wM2wXh5Vrf"
       onChange={onChange}/>
     </div>

     { captchaValido === false && <p className={c.captchaMsj}>Por favor, marca la casilla.</p>}

     <button  id="sobre" onClick={handleSubmit} className={c.button} disabled={Object.keys(errors).length || !captchaValido}>Enviar Mensaje</button>

    </div>

        
    </div>)
}