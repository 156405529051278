// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trabajos_formSpace__e1ts3{
    width: 27.39875rem;
    height: 51.692rem 
}
.trabajos_brand__Fk-h4{
    position: relative;
    padding-top: 1.5rem;
}

.trabajos_ondaBackground__JfdnX{
    position: absolute;
    /* top: 12rem; */
    bottom: -18rem;
    /* height: 1px; */
    z-index: 0;
    left: 0;
    right: 0;
    max-width: 1920px;
    /* min-width: 1400px; */
    margin-left: auto;
    margin-right: auto;
}

.trabajos_onda__CEZB9{
    /* background-size: cover;
    background-position: center;
    width: 100%;
    -webkit-user-drag: none;
    user-select: none; */

    background-size: cover; 
    background-position: center; 
    width: 100%; 
    -webkit-user-drag: none; 
    -webkit-user-select: none; 
    user-select: none;
}

.trabajos_brands__rIDjW{
    position: relative; z-index: 1;
}

@media (max-width: 1055px) {
    .trabajos_ondaBackground__JfdnX{
    position: absolute;
    /* top: 12rem; */
    bottom: unset;
    /* height: 1px; */
    z-index: 0;
    left: 0;
    right: 0;
    max-width: 1920px;
    /* min-width: 1400px; */
    margin-left: auto;
    margin-right: auto;

    
}

.trabajos_formSpace__e1ts3{
    display: none;
}
}


@media (max-width: 768px) {

    .trabajos_formSpace__e1ts3{
        display: none;
    }

    .trabajos_ondaBackground__JfdnX{
        bottom: unset;
    }

    
}`, "",{"version":3,"sources":["webpack://./src/Components/Trabajos/trabajos.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB;AACJ;AACA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,UAAU;IACV,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;;;;wBAIoB;;IAEpB,sBAAsB;IACtB,2BAA2B;IAC3B,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB,EAAE,UAAU;AAClC;;AAEA;IACI;IACA,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,UAAU;IACV,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;;;AAGtB;;AAEA;IACI,aAAa;AACjB;AACA;;;AAGA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;;;AAGJ","sourcesContent":[".formSpace{\n    width: 27.39875rem;\n    height: 51.692rem \n}\n.brand{\n    position: relative;\n    padding-top: 1.5rem;\n}\n\n.ondaBackground{\n    position: absolute;\n    /* top: 12rem; */\n    bottom: -18rem;\n    /* height: 1px; */\n    z-index: 0;\n    left: 0;\n    right: 0;\n    max-width: 1920px;\n    /* min-width: 1400px; */\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.onda{\n    /* background-size: cover;\n    background-position: center;\n    width: 100%;\n    -webkit-user-drag: none;\n    user-select: none; */\n\n    background-size: cover; \n    background-position: center; \n    width: 100%; \n    -webkit-user-drag: none; \n    -webkit-user-select: none; \n    user-select: none;\n}\n\n.brands{\n    position: relative; z-index: 1;\n}\n\n@media (max-width: 1055px) {\n    .ondaBackground{\n    position: absolute;\n    /* top: 12rem; */\n    bottom: unset;\n    /* height: 1px; */\n    z-index: 0;\n    left: 0;\n    right: 0;\n    max-width: 1920px;\n    /* min-width: 1400px; */\n    margin-left: auto;\n    margin-right: auto;\n\n    \n}\n\n.formSpace{\n    display: none;\n}\n}\n\n\n@media (max-width: 768px) {\n\n    .formSpace{\n        display: none;\n    }\n\n    .ondaBackground{\n        bottom: unset;\n    }\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSpace": `trabajos_formSpace__e1ts3`,
	"brand": `trabajos_brand__Fk-h4`,
	"ondaBackground": `trabajos_ondaBackground__JfdnX`,
	"onda": `trabajos_onda__CEZB9`,
	"brands": `trabajos_brands__rIDjW`
};
export default ___CSS_LOADER_EXPORT___;
