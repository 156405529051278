import { useState, useEffect } from "react"
import c from "./carousel.module.css"
import "../../../node_modules/lightbox2/dist/css/lightbox.css";
import "../../../node_modules/lightbox2/dist/js/lightbox.js";
import lightbox from "../../../node_modules/lightbox2/dist/js/lightbox.js"

export default function Carousel({images}) {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [transformValue, setTransformValue] = useState(24.7); 
    /* const [selectedImage, setSelectedImage] = useState(); */
    const [imagesToShow, setImagesToShow] = useState(3);
  
    lightbox.option( {
      'wrapAround': true,
      "disableScrolling": true})

    useEffect(() => {
        const updateImagesToShow = () => {
          if (window.innerWidth <= 473) { 
                setImagesToShow(1);
                setTransformValue(21.1);
            }
          else if (window.innerWidth < 589) { // Móvil
                setImagesToShow(1);
                
                setTransformValue(28.1);
              }
          else if (window.innerWidth < 620) { // Móvil
            setImagesToShow(1);
            
            setTransformValue(32.15);
          } else if (window.innerWidth < 670) { // Móvil
            setImagesToShow(1);
            
            setTransformValue(35.1);
          } else if (window.innerWidth < 720) { // Móvil
            setImagesToShow(1);
            
            setTransformValue(38.1);
          } else if (window.innerWidth < 768) { // Móvil
            setImagesToShow(1);
            
            setTransformValue(42.2);
          } else if (window.innerWidth < 905) { // Móvil
            setImagesToShow(1);
            
            setTransformValue(48.1);
          } else if (window.innerWidth < 955) { // Móvil
            setImagesToShow(1);
            
            setTransformValue(52.2);
          } else if (window.innerWidth >= 955 && window.innerWidth < 1085) { // Tablet pequeño
            setImagesToShow(1);
            setTransformValue(58);
          } else if (window.innerWidth >= 1085 && window.innerWidth <= 1260) { // Tablet grande
            setImagesToShow(2);
            setTransformValue(34.7);}
             else { // Escritorio
            setImagesToShow(3);
            setTransformValue(24.7);
          }
        };
      
        updateImagesToShow(); // Ejecutar al cargar el componente
        window.addEventListener("resize", updateImagesToShow); // Actualizar al redimensionar
        return () => window.removeEventListener("resize", updateImagesToShow);
      }, []);

    /* const selectNewImage = (index, images, next = true) => {
       
       const condition = next ? selectedIndex < images.length - 3 : selectedIndex > 0;

       const nextIndex = next ? condition ? selectedIndex + 1 : 0
       : condition ? selectedIndex - 1 : images.length - 3

       setSelectedImage(images[nextIndex]) 
       setSelectedIndex(nextIndex)
    } */

    const selectNewImage = (index, images, next = true) => {
        
        const condition = next
          ? selectedIndex < images.length - imagesToShow
          : selectedIndex > 0;
      
        const nextIndex = next
          ? condition ? selectedIndex + 1 : 0
          : condition ? selectedIndex - 1 : images.length - imagesToShow;
      
        /* setSelectedImage(images[nextIndex]); */
        setSelectedIndex(nextIndex);
      };

    const prev = () =>{
       selectNewImage(selectedIndex, images, false);
    }

    const next = () =>{
        selectNewImage(selectedIndex, images);
    }

    return(<>
    <div className={c.carousel}>
    <button className={`${c.buttonPage} ${c.buttonPrev}`} onClick={prev}>
        <svg className={c.buttonSize} width="42" height="79" viewBox="0 0 42 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="2.04335" y1="39.6291" x2="40.3709" y2="77.9566" stroke="white" strokeWidth="2"/>
<line x1="1.29289" y1="39.6205" x2="39.6204" y2="1.293" stroke="white" strokeWidth="2"/>
        </svg>
</button>
    <div className={c.container}>
      <div className={c.carouselTransition} style={{
          display: 'flex',
          /* transform: `translateX(-${selectedIndex * 24.7}rem)` */
          transform: `translateX(-${selectedIndex * transformValue}rem)`, // Desplaza las imágenes según el índice
          transition: 'transform 0.5s ease-in-out', // Efecto de desplazamiento
        }}>
        {images.map((img, index) =>
  
         <a href={img} data-lightbox="image-1" data-title="Nuestros Trabajos"><img src={img} className={c.img} alt="" loading="lazy"/></a>
        
         )}
        
        </div>
    </div>
    
    <button className={`${c.buttonPage} ${c.buttonNext}`} onClick={next}>
    <svg className={c.buttonSize} width="41" height="79" viewBox="0 0 41 79" fill="none" xmlns="http://www.w3.org/2000/svg">
     <line x1="39.2841" y1="39.0345" x2="0.95657" y2="0.706965" stroke="white" strokeWidth="2"/>
     <line x1="40.0345" y1="39.043" x2="1.70697" y2="77.3706" stroke="white" strokeWidth="2"/>
     </svg>
        </button>
    </div>
    
    <div className={c.buttonsDiv}>
    <button className={c.buttonPage} onClick={ () => setSelectedIndex(0)}>
        <div className={(selectedIndex >= 0 && selectedIndex <= 2) ? c.buttonOff : c.buttonOn}></div>
    </button>
    <button className={c.buttonPage} onClick={ () => setSelectedIndex(3)}>
        <div className={(selectedIndex >= 3 && selectedIndex <= 5) ? c.buttonOff : c.buttonOn}></div>    
    </button>
    <button className={c.buttonPage} onClick={ () => setSelectedIndex(6)}>
        <div className={(selectedIndex >= 6 && selectedIndex <= 9) ? c.buttonOff : c.buttonOn}></div>
    </button>
    </div>
    

    </>)
}