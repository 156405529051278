import c from "./ourworksextended.module.css";

export default function OurWorksExtended() {
    
    return(<div id="trabajos">
      
      <div className={c.ourworks1}>
  
      <div className={c.margin}>
      <h1>NUESTROS TRABAJOS</h1>

      <div className={c.imgSection1}>
      
      <div className={c.imgContainer1}>
        <img className={c.img1} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847549/Climatizaciones/o6qw04kagptsvt4iptir.png" alt="" />

        <img className={c.img2} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730268868/Climatizaciones/srutttxcqdkrildebzcc.jpg" alt="" />
      </div>

      <div className={c.imgContainer2}>
        <img className={c.img3} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730268844/Climatizaciones/vvecl714rdnbrl4ittpk.jpg" alt="" />

        <img className={c.img4} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730268868/Climatizaciones/w511yb9nxmr3ea9o8rtd.jpg" alt="" />
      </div>

      </div>

      </div>
    </div>

    
    <div className={c.ourworks2}>
       
       <div className={c.margin2}>
          
       <div className={c.imgSection2}>
      
      <div className={c.imgContainer3}>
        <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730268844/Climatizaciones/hmieb2wln1zu8wa7nsjb.jpg" className={c.img5} alt="" />
      </div>

      <div className={c.imgContainer4}>
        
        <div className={c.img6Container}><img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847569/Climatizaciones/uox2ejqhnedrtqsavq5c.jpg" className={c.img6} alt="" /></div>
        
        
        <div className={c.img7Container}><img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847563/Climatizaciones/rsstbrz4yeiiqprhlzpp.jpg" className={c.img7} alt="" /></div>
        
        
        <div className={c.img8Container}><img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730304481/Climatizaciones/ps98htekd1eeacbghjmt.jpg" className={c.img8} alt="" /></div>
        
      </div>

      </div>

       </div>

    </div>


    <div className={c.ourworks3}>
       
       <div className={c.margin}>
          
       <div className={c.imgSection3}>
      
       <div className={c.imgContainer5}>
        <img className={c.img1} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847558/Climatizaciones/rwlf4izwm6krenzcmm9d.png" alt="" />

        <img className={c.img2} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730304480/Climatizaciones/ytzkpd7fxoplic3sno1z.jpg" alt="" />
      </div>

      <div className={c.imgContainer6}>
        
        <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1730268844/Climatizaciones/purbwycmdovc7w1tf5ci.jpg" className={c.img11} alt="" />
        
      </div>

      </div>

       </div>
   

       <div className={c.descripcion}>
        <p>Realizado por <span className={c.semiBold}>PROFESIONALES</span> en el sector<br/>
Con más de <span className={c.bold}>15 años</span> de trayectoria en el rubro <span className={`${c.italic} ${c.semiBold}`}>MTClimatización©</span> <br/>continúa marcando la pauta en soluciones de <span className={c.semiBold}>confort y eficiencia.</span></p>
       </div>

       <div id="garantia" className={c.ondaBackground}>
       <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848954/Climatizaciones/cvstndqfu82moahwu36t.png" className={c.onda} alt="" />
    </div> 


    </div>


      

    </div>)
}