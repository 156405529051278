// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-color: #031521;
  background-size: cover;
  /* height: 100vh; */
  font-family: "Montserrat", sans-serif;
  color: white;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;AACd","sourcesContent":[".App {\n  text-align: center;\n  background-color: #031521;\n  background-size: cover;\n  /* height: 100vh; */\n  font-family: \"Montserrat\", sans-serif;\n  color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
