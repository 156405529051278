import c from "./iconscontact.module.css"
import logo1 from "./valija.svg";
import logo2 from "./money.svg";
import logo3 from "./shield.svg";
import logo4 from "./clients.svg";

export default function IconsContact() {

    return(<div className={c.iconsContact}>

    <div className={c.container}>

        <div className={c.contact}>
            <h1>Podés contactarte con nosotros por:</h1>

            <div className={c.text4}> <a href="https://wa.link/e023hh" target="_blank" rel="noreferrer">WhatsApp</a></div>
        </div>

        <div className={c.icons}>
           
           <div>
            
           <div className={c.logo}>
                <img src={logo1} className={c.logoImg} alt="" loading="lazy"/>
            </div>

            <h3>Equipo Técnico Profesional</h3>

           </div>
            

           <div>
            
           <div className={c.logo}>
                <img src={logo2} className={c.logoImg} alt="" loading="lazy"/>
            </div>

            <h3>Servicio de Alta Calidad</h3>

           </div>

           <div>
            
            <div className={c.logo}>
                 <img src={logo3} className={c.logoImg} alt="" loading="lazy"/>
             </div>
 
             <h3>Precios Accesibles</h3>
 
            </div>

            <div>
            
           <div className={c.logo}>
                <img src={logo4} className={c.logoImg} alt="" loading="lazy"/>
            </div>

            <h3>Clientes Felices</h3>

           </div>
        </div>

    </div>
    

    </div>)
}