import c from "./home.module.css"
import FormSection from "../Form Section/FormSection"
import Header from "../Header/Header"
import Form from "../Form/Form"
import About from "../About/About"
import Brands from "../Brands/Brands"
import List from "../List/List"
import Services from "../Services/Services"
import OurWorks from "../Our Works/OurWorks"
import ChooseUs from "../Choose Us/ChooseUs"
import Contact from "../Contact/Contact"
import Footer from "../Footer/Footer"
import Links from "../Links/Links"

export default function Home() {

    return(<div className={c.home}>
        
        <Header/>
     
        <FormSection form={<Form/>} backImg={"https://res.cloudinary.com/dapq4icmj/image/upload/v1729847904/Climatizaciones/b8oakrmpj7avxaqikeo2.jpg"}/>

        <About/>

        <Brands list={<List paddingBottom={"5rem"}/>} bandColor={"black"} brandBack={"https://res.cloudinary.com/dapq4icmj/image/upload/v1729848383/Climatizaciones/ara2zkwsfadt5bb9uhay.jpg"} />

        <Services/>

        <OurWorks/>

        <ChooseUs/>

        <Contact/>

        <Links/>

        <Footer/>

    </div>)
}