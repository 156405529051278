import c from "./brands.module.css"


export default function Brands({list, brandBack, bandColor, fontColor}) {
    
    return(<div className={c.brands} style={ brandBack? {
      backgroundImage: `url(${brandBack}), linear-gradient(rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58))`,
      width: '100%',
      backgroundBlendMode: 'overlay',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      
    }: null}>
    
    <div className={c.band}>
      <div className={c.bandContainer} style={{background: `${bandColor}`, color: `${fontColor}`}}>
      ¡TRABAJAMOS CON TODAS LAS MARCAS DE CALDERAS!
      </div>
     </div>

    <div className={c.container}>{list}</div>

    </div>)
}