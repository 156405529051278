import c from "./footer.module.css"

export default function Footer() {

    return(<div className={c.footer}>
  
        <div className={c.container}>
        
        Copyright © 2024 MTClimatización

        </div>

    </div>)
}