import c from "./trabajos.module.css"
import Header from "../Header/Header"
import FormSection from "../Form Section/FormSection"
import Brands from "../Brands/Brands"
import List from "../List/List"
import OurWorksExtended from "../OurWorksExtended/OurWorksExtended"
import IconsContact from "../IconsContact/IconsContact"
import Contact from "../Contact/Contact"
import Footer from "../Footer/Footer"
import Links2 from "../Links2/Links2"

export default function Trabajos(){

    return(<div className={c.trabajos}>
    
    <Header/>
     
    <FormSection form={<div className={c.formSpace}></div>} backImg={"https://res.cloudinary.com/dapq4icmj/image/upload/v1730236652/Climatizaciones/o6oxnzobtm8khdo6z7gm.jpg"}/>
    
    <div className={c.brand}>
    <Brands list={<List paddingBottom={"0rem"}/>} bandColor={"orange"} fontColor={"#345063"}/>
 
    <div className={c.ondaBackground}>
       <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848954/Climatizaciones/cvstndqfu82moahwu36t.png" className={c.onda} alt="" />
    </div>

    </div>

    <OurWorksExtended />

    <IconsContact/>

    <Contact/>

    <Links2/>
      
    <Footer/>

    </div>)
}