import c from "./header.module.css"
import { Link } from "react-router-dom";
import { useState } from "react";
import burguerNav from "./burguerNav.svg"

export default function Header() {

      const [isOpen, setIsOpen] = useState(false);

      const toggleMenu = () => {
          setIsOpen(!isOpen);
      };

    return(<div className={c.header} >
    
    <div className={c.container}>

      <div className={c.block}></div>
     <div className={c.logoContainer}>
         <img  className={c.logo} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847749/Climatizaciones/jq7jluduurgjicbxloh9.png" alt="" />
     </div>
 
     

     <div className={c.display}>
        <nav className={c.burguerNav}>
            <div className={c.burguerIcon} onClick={toggleMenu}>
                <img src={burguerNav} alt="" />
            </div>
            
            <div className={!isOpen ? c.burguerInfoDisabled : c.burgerInfo}>
            <Link className={c.link1} to="/" onClick={() => {
        toggleMenu(); 
        window.scrollTo(0, 0);
    }}>
              <div className={c.linkContainer}>
              Inicio
              </div>
            </Link>
             
            <Link className={c.link1} to="/trabajos" onClick={() => {
        toggleMenu(); 
        window.scrollTo(0, 0);
    }}>
              <div className={c.linkContainer}>
              Trabajos
              </div>
            </Link>
            </div> 
        </nav>
     </div>

     <div className={c.buttons}>
 
         <div className={c.button1}>
             <Link className={c.link} to="/" onClick={() => {window.scrollTo(0, 0);}}>
            <p>Inicio</p></Link>
         </div>
 
         <div className={c.button2}>
            <Link className={c.link} to="/trabajos" onClick={() => {window.scrollTo(0, 0);}}><p>Trabajos</p></Link>
         </div>
 
     </div>
 
    </div>
    </div>)

} 