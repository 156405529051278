import c from "./about.module.css"

export default function About() {


    return(<div className={c.about}>
    
    <div  className={c.container}>
     
      <div className={c.imgCont}>
        <img className={c.imgAbout} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848310/Climatizaciones/delt3s94avexifclua2r.jpg" alt="" />
      </div>

      <div className={c.text}>
        <h2 >SOBRE NOSOTROS</h2>
        
        <p><span className={c.italic}>MTClimatización</span> ofrece soluciones para las necesidades de climatización de <span className={c.orange}>empresas</span>, <span className={c.orange}>hogares y técnicos instaladores</span>.</p>

        <div>
            <ul>
                <li>Ofrecemos soluciones prácticas, confiables y a costo razonable en el área de Calderas y Aire acondicionado.</li>
                <li>Nuestros técnicos altamente cualificados están preparados para resolver cualquier situación con la máxima eficiencia y profesionalismo.</li>
            </ul>
        </div>
      </div> 

 
    </div>


    </div>)
}