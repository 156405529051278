import c from "./paginado.module.css";
/* import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css'; */
import Carousel from "../Carousel/Carousel";

export default function Paginado({images}) {
    
    /* const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(1); 
    const totalItems = images.length;
 */
    /* const updateItemsPerPage = () => {
        if (window.innerWidth <= 768) {
            setItemsPerPage(1);
        } else {
            setItemsPerPage(3); 
        }
    };
 */
    
    /* useEffect(() => {

        updateItemsPerPage(); */
        /* window.addEventListener("resize", updateItemsPerPage);

        return () => window.removeEventListener("resize", updateItemsPerPage); */
    /* }, []); */

    /* const next = () => {
        if (currentIndex >= totalItems - itemsPerPage) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    }; */

    /* const previous = () => {
    if (currentIndex === 0) {
        setCurrentIndex(totalItems - itemsPerPage);
    } else {
        setCurrentIndex(currentIndex - itemsPerPage);
    }
}; */




    return(<div className={c.paginado}>

        {/* <div className={c.container}>
            <div className={c.imgContainer}>
                <button onClick={previous} >
                <svg className={c.buttonPage} width="42" height="79" viewBox="0 0 42 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="2.04335" y1="39.6291" x2="40.3709" y2="77.9566" stroke="white" strokeWidth="2"/>
<line x1="1.29289" y1="39.6205" x2="39.6204" y2="1.293" stroke="white" strokeWidth="2"/>
</svg>

                </button>
                {images.slice(currentIndex, currentIndex + itemsPerPage).map((image, index) => (
                    <div className={c.imgBorder} key={index}>
                        <img src={image} className={c.img} alt={`Slide ${currentIndex + index + 1}`} />
                    </div>
                ))}
                <button onClick={next} >
                <svg className={c.buttonPage} width="41" height="79" viewBox="0 0 41 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="39.2841" y1="39.0345" x2="0.95657" y2="0.706965" stroke="white" strokeWidth="2"/>
<line x1="40.0345" y1="39.043" x2="1.70697" y2="77.3706" stroke="white" strokeWidth="2"/>
</svg>

            </button>
            </div>
            
        </div>  */} 

        <Carousel images={images}/>


{/* <Carousel>
      <Carousel.Item>
        <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847549/Climatizaciones/o6qw04kagptsvt4iptir.png" text="First slide" alt="First slide"/>
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847555/Climatizaciones/xywfcfs41c87bksv323g.png" text="Second slide" alt="Second slide"/>
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847558/Climatizaciones/rwlf4izwm6krenzcmm9d.png" text="Third slide" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}



    </div>)
}