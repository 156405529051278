import Form from "../Form/Form"
import c from "./contact.module.css"


export default function Contact() {
   
    return(<div className={c.contact}>

        <div className={c.container}>

            <div className={c.leftZone}>

              <p className={c.contacto}>CONTACTO</p>

              <div className={c.item}>
                <div className={c.itemImg}><img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729849752/Climatizaciones/msvpnvtafhg1ga6ampy0.png" className={c.mail} alt="" /></div>

                <div className={c.itemText}><p>mtclimatizaciones@gmail.com</p></div>
              </div>

              <div className={c.item}>
                <div className={c.itemImg}><img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729849749/Climatizaciones/tnbsrtl31gdvyes2gv7b.png" className={c.location} alt="" /></div>

                <div className={c.itemText}><p>Solís 843. Caba</p>  </div>
              </div>
              
              <div className={c.item}>  
                <div className={c.itemImg}>
                <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729849743/Climatizaciones/vde4b5fz76yzeyhdc33u.png" className={c.phone} alt="" />
                </div>

                <div className={c.itemText}>
                <p>+54 9 11 7228-7000</p>
                </div>
              </div>

            </div>

            <Form />

            <div className={c.rightZone}>
              <div className={`${c.horario}  ${c.itemRight}`}>  
                <div className={c.itemImg}>
                <img src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729849743/Climatizaciones/vde4b5fz76yzeyhdc33u.png" className={c.phone} alt="" />
                </div>

                <div className={c.itemText}>
                <p >Horario de Atención:<br></br>8 a 19 hs</p>
                </div>
              </div>
              
              <div className={c.map}>

              <p>Nuestras Oficinas</p>
              
              <iframe title="Mapa de ubicación de la empresa" className={c.iframe} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.4311984525702!2d-58.3904267!3d-34.6185421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccadf15b0e7bb%3A0x30818e1313905f9a!2sSol%C3%ADs%20843%2C%20C1078AAQ%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1730137491344!5m2!1ses!2sar" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
              

              </div>

        </div>

    </div>)
}