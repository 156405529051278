import c from "./list.module.css";

export default function List({innerBackground, paddingBottom}) {
    
    return(<div className={c.list}>

    <div className={c.container} style={{paddingBottom: `${paddingBottom}`}}>
        
     {/* <div style={{ background: innerBackground }} className={c.band}>
      <div className={c.bandContainer}>
      ¡TRABAJAMOS CON TODAS LAS MARCAS DE CALDERAS!
      </div>
     </div> */}

     <div className={c.imgCont}>
       <img className={c.peisa} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848408/Climatizaciones/zgm5clxa8rs1girqv9sn.webp" alt="" loading="lazy"/>
       <img className={`${c.baxi}`}  src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848569/Climatizaciones/hczxhbkbnin2y0htwkmu.png" alt="" loading="lazy"/>
       <img className={c.ariston} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848584/Climatizaciones/z86fcvjedvmehbxryl69.png" alt="" loading="lazy"/>
       <img className={c.caldaia} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848401/Climatizaciones/ed2h6hueiwum5komaxw8.png" alt="" loading="lazy"/>
       <img className={c.ferroli} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848412/Climatizaciones/diavoxj0wjsz8hofhudl.png" alt="" loading="lazy"/>
     </div>

    </div>

    </div>)
}