// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.paginado_paginado__4TIII{}

.paginado_container__uscHD{
    
}

.paginado_img__oIfJ0{
    width: 20rem;
    height: 29rem;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    border: white solid 1.5px;
}

.paginado_imgContainer__cuR0n{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    
}

.paginado_imgBorder__c5lP7{
}

.paginado_imgContainer__cuR0n button{
    all: unset;
    cursor: pointer;
}





@media (max-width: 768px) {
    
    .paginado_imgBorder__c5lP7{
        display: flex;
       flex-direction: column;
    }

    .paginado_imgContainer__cuR0n{
        display: flex;
        flex-direction: row;
    }
    
    .paginado_buttonPage__RzORQ{
        width: 1.5rem;
    }

}`, "",{"version":3,"sources":["webpack://./src/Components/Paginado/paginado.module.css"],"names":[],"mappings":";AACA,0BAAU;;AAEV;;AAEA;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;;AAEjC;;AAEA;AACA;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;;;;;AAMA;;IAEI;QACI,aAAa;OACd,sBAAsB;IACzB;;IAEA;QACI,aAAa;QACb,mBAAmB;IACvB;;IAEA;QACI,aAAa;IACjB;;AAEJ","sourcesContent":["\n.paginado{}\n\n.container{\n    \n}\n\n.img{\n    width: 20rem;\n    height: 29rem;\n    background-size: cover;\n    background-position: center;\n    border-radius: 10px;\n    border: white solid 1.5px;\n}\n\n.imgContainer{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around; \n    \n}\n\n.imgBorder{\n}\n\n.imgContainer button{\n    all: unset;\n    cursor: pointer;\n}\n\n\n\n\n\n@media (max-width: 768px) {\n    \n    .imgBorder{\n        display: flex;\n       flex-direction: column;\n    }\n\n    .imgContainer{\n        display: flex;\n        flex-direction: row;\n    }\n    \n    .buttonPage{\n        width: 1.5rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginado": `paginado_paginado__4TIII`,
	"container": `paginado_container__uscHD`,
	"img": `paginado_img__oIfJ0`,
	"imgContainer": `paginado_imgContainer__cuR0n`,
	"imgBorder": `paginado_imgBorder__c5lP7`,
	"buttonPage": `paginado_buttonPage__RzORQ`
};
export default ___CSS_LOADER_EXPORT___;
