import c from "./links2.module.css"
import line from "./line.svg"

export default function Links2(){

    return(<div className={c.links}>

        <div className={c.container}>

         <div>  

            <div>
                <h2><a href="#marcas" style={{all: "unset", cursor: "pointer"}}>Marcas</a></h2>

                <img src={line} alt="" />

            </div>
            
            <div className={c.logoContainer}>
            <img  className={c.logo} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729847749/Climatizaciones/jq7jluduurgjicbxloh9.png" alt="" loading="lazy"/>
            <p className={c.text5}>tu compañía de confianza.</p>
            </div>
         </div>

         <div>
         <h2><a href="#trabajos" style={{all: "unset", cursor: "pointer"}}>Trabajos</a></h2>
         <img src={line} alt="" />
         </div>

         <div>
         <h2><a href="#garantia" style={{all: "unset", cursor: "pointer"}}>Garantías</a></h2>
         <img src={line} alt="" />
         </div>

         <div>
            <h2>Horario de Atención</h2>

            <img src={line} alt="" />

            <p style={{marginTop:"1.5rem"}}>Lunes a Sábados</p>

            <p>08:00 - 21:00</p>
          <div className={c.contactar}>
          <div className={c.chooseTitle}>
          <a href="https://wa.link/e023hh" style={{all: "unset"}} target="_blank" rel="noreferrer">Contactar</a>
            </div>
         </div>
         </div>


        </div>

    </div>)

}