import c from "./formsection.module.css"

export default function FormSection({form, backImg}) {

    return(<div className={c.formSection} style={{
        backgroundImage: `url(${backImg}), linear-gradient(rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58))`,
        width: '100%',
        backgroundBlendMode: 'overlay',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        
        <div className={c.container}>
          
         <div className={c.textContainer}>

          <div className={c.text}>
          
            <h1>SERVICIO TÉCNICO</h1>
            
            <div className={c.h2Div}>
                <h2>CLIMATIZACIÓN Y CALDERAS</h2>
            </div>
            
          </div>
          
          <div className={c.textImg}>
           
            <div className={c.checkCont}>
                <img className={c.checkImg} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848142/Climatizaciones/tyl1om9q6cv4uw4crw8w.png" alt="" />
                <p className={c.text1}>SERVICIO Y MANTENIMIENTO</p>
            </div>

            <div className={c.plusCont}>
                <p className={c.plus}>+</p>
                <p className={c.text2}>15 AÑOS DE CONFIANZA Y RESULTADOS</p>
            </div>

            <div className={c.wppCont}>
                <img className={c.wppImg} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848153/Climatizaciones/sxgo5xgsiqhu47mq1cek.png" alt="" />
                <p className={c.text3}>+54 9 11 7228-7000</p>
            </div>

            <div  className={c.text4}> <a href="https://wa.link/e023hh" target="_blank" rel="noreferrer">WhatsApp</a></div>

            <div id="marcas" className={c.logoCont}>
                <img className={c.logoPng} src="https://res.cloudinary.com/dapq4icmj/image/upload/v1729848009/Climatizaciones/f2bq8hptwjjwrozzzkz0.png" alt="" />
                <p   className={c.text5}>tu compañía de confianza.</p>
            </div>

          </div>

         </div>



         {form && <div >{form}</div>}

        </div>
 
        
    </div>)
}